<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div uk-grid>
          <div class="uk-width-1-2@s uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="form-horizontal-text"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="form-horizontal-text"
                v-model="metaFilter.name"
                class="uk-input"
                type="text"
                placeholder="Cari jenis ayam / user..."
              >
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <label
              class="uk-form-label filter-margin"
              for="form-horizontal-text"
            >Tanggal Diubah</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="updatedStart"
                    v-model="metaFilter.updated_start"
                    input-class="uk-input"
                    placeholder="DD-MM-YYYY"
                  />  
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="updatedEnd"
                    v-model="metaFilter.updated_end"
                    input-class="uk-input"
                    placeholder="DD-MM-YYYY"
                  />  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-margin uk-grid">
          <div class="uk-width-1-2@s first-column">
            <label
              class="uk-form-label filter-margin"
              for="status"
            >
              Status
            </label>
            <div class="uk-form-controls">
              <select
                id="status"
                v-model="metaFilter.status"
                class="uk-input uk-select" 
                name="status"
              >
                <option value="">
                  Semua
                </option>
                <option value="true">
                  Aktif
                </option>
                <option value="false">
                  Tidak Aktif
                </option>
              </select>
            </div>
          </div>
          <div class="uk-width-1-2@s uk-text-right">
            <button
              class="uk-button uk-button-default uk-margin-left"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-left"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="uk-margin">
      <router-link class="uk-button uk-button-default" to="/admin/user/add"><span uk-icon="icon: plus; ratio: 0.7" class="uk-margin-small-right"></span>Tambah User</router-link>
    </div> -->
    <div class="uk-margin">
      <button
        class="uk-button uk-button-default"
        @click="toImport()"
      >
        <span
          uk-icon="icon: upload; ratio: 0.7"
          class="uk-margin-small-right"
        />Import
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { dateString } from '@/utils/formater'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      metaFilter: {
        name: '',
        status: ''
      }
    }
  },
  computed : {
    ...mapGetters({
      meta: 'chickenCategory/meta'
    })
  },
  watch: {
    async 'metaFilter.updated_start'() {
      this.metaFilter.updated_start = this.dateFormatFilter(this.metaFilter.updated_start)
    },
    async 'metaFilter.updated_end'() {
      this.metaFilter.updated_end = this.dateFormatFilter(this.metaFilter.updated_end)
    }
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    ...mapActions({
    }),
    ...mapMutations({
      setMeta: 'chickenCategory/SET_META'
    }),
    async handleFind () {
      this.metaFilter.page = 1
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      this.$refs.updatedStart.clearDate()
      this.$refs.updatedEnd.clearDate()
      this.metaFilter.name = ''
      this.metaFilter.status = ''
      await this.handleFind()
    },
    toImport() {
      this.$router.push(`/admin/jenis-ayam/import`)
    }
  }
}
</script>
