<template>
  <div class="uk-margin-top">
    <table class="uk-table uk-table-striped uk-table-hover">
      <thead class="thead-table-paranje">
        <tr>
          <th class="uk-width-small">
            <span class="uk-text-bold">Jenis Ayam</span>
          </th>
          <th class="uk-width-small">
            <span class="uk-text-bold">Catatan</span>
          </th>
          <th class="uk-width-small">
            <span class="uk-text-bold">Terakhir Di Ubah</span>
          </th>
          <th class="uk-width-small">
            <span class="uk-text-bold">Diubah Oleh</span>
          </th>
          <th class="uk-width-small">
            <span class="uk-text-bold">Status</span>
          </th>
          <th class="uk-width-small">
            <span class="uk-text-bold">Aksi</span>
          </th>
        </tr>
      </thead>
      <template v-if="!is_loading">
        <tbody v-if="chickenCategorys.meta.total_count>0">
          <tr
            v-for="(chick, i) in chickenCategorys.data"
            :key="i"
          >
            <td>{{ chick.name ? chick.name : '-' }}</td>
            <td>{{ chick.note ? chick.note : '-' }}</td>
            <td>{{ formatDate(chick.updated_at) }}</td>
            <td>{{ chick.updated_by ? chick.updated_by : '-' }}</td>
            <td>
              <span
                v-if="chick.status === true"
                class="uk-text-success"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger"
              >{{ status.INACTIVE }}</span>
            </td>
            <td>
              <img
                v-lazy="`${images}/icon/eye.svg`"
                alt=""
                class="img-click"
                @click="showDetailModal(chick.id)"
              >
              <!-- <img v-lazy="`${images}/icon/edit.svg`" alt="" class="uk-margin-right img-click" @click="showEditModal(chick.id)">
              <img v-if="chick.name_employee_role !== 'Manager Divisi'" v-lazy="`${images}/icon/delete.svg`" alt="" class="img-click" @click="showDeleteConfirmModal(employee)"> -->
            </td>
          </tr>
        </tbody>
        <empty-table
          v-else
          :colspan="6"
        />
      </template>
      <template v-else>
        <loading-table :colspan="6" />
      </template>
    </table>
    <pagination
      :total-data="chickenCategorys.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      chickenCategorys: 'chickenCategory/chickenCategorys',
      meta: 'chickenCategory/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getChickenCategory(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getChickenCategory(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    ...mapActions({
      getChickenCategory: 'chickenCategory/getChickenCategory'
    }),
    ...mapMutations({
      setMeta: 'chickenCategory/SET_META',
      setModalDelete: 'chickenCategory/SET_MODAL_DELETE'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetailModal (id) {
      this.$router.push(`/admin/jenis-ayam/detail/${id}`)
    },
    showEditModal (id) {
      this.$router.push({name: 'Update Pekerja', params: { id: id }})
    },
    showDeleteConfirmModal (employee) {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(employee)
    }
  }
}
</script>
